<script lang="ts">
	import Analytics from '$components/Analytics.svelte';

	import '../style/app.css';

	import { beforeNavigate, invalidate } from '$app/navigation';
	import { updated } from '$app/stores';
	import { windowWidth } from '$stores/stores';

	import type { Subscription } from '@supabase/supabase-js';
	import { onDestroy, onMount } from 'svelte';
	import type { LayoutData } from './$types';

	export let data: LayoutData;
	$: ({ sb, session } = data);

	let subscription: Subscription;

	// Should fix white page due to errors on try to import missing module
	beforeNavigate(({ to, willUnload }) => {
		if ($updated && willUnload && to?.url) {
			location.href = to.url.href;
		}
	});

	onMount(() => {
		({
			data: { subscription }
		} = sb.auth.onAuthStateChange((event, _session) => {
			if (_session?.expires_at !== session?.expires_at) {
				invalidate('supabase:auth');
			}
		}));
	});

	onDestroy(() => {
		if (subscription) subscription.unsubscribe();
	});
</script>

<svelte:window bind:innerWidth={$windowWidth} />

<slot />

<Analytics />
