//import { browser } from '$app/environment';
import { PUBLIC_SUPABASE_ANON_KEY, PUBLIC_SUPABASE_URL } from '$env/static/public';
import type { Database } from '$lib/database.types';
import { session as s } from '$stores/stores';
import { combineChunks, createBrowserClient, isBrowser, parse } from '@supabase/ssr';
import type { Session } from '@supabase/supabase-js';
import { redirect } from '@sveltejs/kit';
import type { LayoutLoad } from './$types';

export const load: LayoutLoad = async ({ depends, url }) => {
	depends('supabase:auth');

	let session: Session | null = null;

	const sb = createBrowserClient<Database>(PUBLIC_SUPABASE_URL, PUBLIC_SUPABASE_ANON_KEY, {
		global: {
			fetch
		},
		cookies: {
			get(key) {
				if (!isBrowser()) {
					return JSON.stringify(session);
				}

				const cookie = combineChunks(key, (name) => {
					const cookies = parse(document.cookie);
					return cookies[name];
				});
				return cookie;
			}
		},
		auth: {
			persistSession: isBrowser(),
			autoRefreshToken: true
		}
	});

	if (isBrowser()) {
		let error;
		({
			data: { session },
			error // eslint-disable-line prefer-const
		} = await sb.auth.getSession());
		if (error) {
			console.log("Couldn't fetch session, thrown in middleware", error);
		} else if (session) s.set(session); // Save in store for Authorization token in wrapped requests

		// Redirect to /app
		if (session && (url.pathname === '/auth/login' || url.pathname === '/auth/register')) {
			redirect(303, '/auth/redirect-to-app');
		}
		// Redirect to /auth/login
		if (!session && url.pathname.startsWith('/app')) {
			redirect(303, '/auth/logout');
		}
	}

	return { sb, session };
};
